import { ErrorCard, Errors } from '@commerce-webcomponents/ui';
import Layout from '../components/Layout/Layout';
import withNextGenISR from '../utils/withNextGenISR';
import SEOComponent from 'components/SEOComponent/SEOComponent';
import { CommonDataProps, PageCategory } from 'types/common';
import { getContentEssentials } from 'utils/getEssentials';
import { getInvalidateTime } from 'utils/common';
import { i18n } from 'i18n.config';

export default function FourOhFour(props: CommonDataProps) {
  const header = props.data?.layout?.header;
  const footer = props.data?.layout?.footer;
  const onActionClick = () => {
    // TODO: Revisit Once 404 Is Fixed For Localization
    window.location.href = '/';
  };
  return (
    <Layout hasTopMobileHeader={true} header={header} footer={footer}>
      <SEOComponent />
      <ErrorCard type={Errors.NotFound} onActionClick={onActionClick} />
    </Layout>
  );
}

export const getStaticProps = withNextGenISR(async () => {
  const { data, showError } = await getContentEssentials({ locale: i18n.defaultLocale, uId: '404' });
  return {
    props: {
      data,
      showError,
    },
    revalidate: getInvalidateTime(PageCategory.DEFERRED),
  };
});
